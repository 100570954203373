const hardcodedVirtualRegions = [
  {
    slug: "idaho",
    name: "Idaho",
    statePostalAbbreviation: "ID",
    latitude: 44.0682,
    longitude: -114.742,
  },
  {
    slug: "illinois",
    name: "Illinois",
    statePostalAbbreviation: "IL",
    latitude: 41.8336479,
    longitude: -87.8720463,
  },
  {
    slug: "connecticut",
    name: "Connecticut",
    statePostalAbbreviation: "CT",
    latitude: 41.2982831,
    longitude: -72.9641152,
  },
  {
    slug: "georgia",
    name: "Georgia",
    statePostalAbbreviation: "GA",
    latitude: 33.7676931,
    longitude: -84.4906437,
  },
  {
    slug: "michigan",
    name: "Michigan",
    statePostalAbbreviation: "MI",
    latitude: 42.3526897,
    longitude: -83.1692445,
  },
  {
    slug: "maine",
    name: "Maine",
    statePostalAbbreviation: "ME",
    latitude: 45.2538,
    longitude: -69.4455,
  },
  {
    slug: "new-hampshire",
    name: "New Hampshire",
    statePostalAbbreviation: "NH",
    latitude: 43.1939,
    longitude: -71.5724,
  },
  {
    slug: "new-york",
    name: "New York",
    statePostalAbbreviation: "NY",
    latitude: 40.7128,
    longitude: -74.006,
  },
  {
    slug: "north-carolina",
    name: "North Carolina",
    statePostalAbbreviation: "NC",
    latitude: 35.2028147,
    longitude: -81.1269099,
  },
  {
    slug: "oregon",
    name: "Oregon",
    statePostalAbbreviation: "OR",
    latitude: 43.8041,
    longitude: -120.5542,
  },
  {
    slug: "rhode-island",
    name: "Rhode Island",
    statePostalAbbreviation: "RI",
    latitude: 41.5801,
    longitude: -71.4774,
  },
];

export default hardcodedVirtualRegions;
