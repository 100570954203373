import Notice, {NoticeType} from "@components/Maps/Notice";
import {BtnProps} from "@components/V5Comp";
import {Collapse} from "@mui/material";
import {FeatureFlag} from "@services/featureFlagConstants";
import {fetchFeatureFlags} from "@services/featureFlags";
import {IconName} from "@services/types";
import moment from "moment-timezone";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {bannerSpacer} from "../../constants/dynamicBanner";
import {useTypedDispatch, useTypedSelector} from "../../store";
import useScroll from "../../useScroll";
import {compact} from "../../utils/arrays";
import {addToClosedDynamicBannerList} from "../../utils/browser-storage/dynamicBanner";
import {useLocalStorageItem} from "../../utils/browser-storage/useBrowserStorage";
import {LocalStorageKey} from "../../utils/localStorage";
import {useWindowSize} from "../_common/Carbon";

type DynamicBannerData = {
  when: string;
  text: string | JSX.Element;
  subtext?: string;
  id: string;
  pathsInclude?: string[];
  pathsExclude?: string[];
  type?: NoticeType;
  icon?: IconName;
  hideIcon?: boolean;
  wrapperClasses?: string;
  states?: string[];
  btnText?: string;
  href?: BtnProps["href"];
  onCtaClick?: () => void;
};

/*
eg:
const banners: DynamicBannerData[] = [
  {
    id: "thanksgiving",
    when: "11/11-11/25", // for range dates yearly (best for holidays)
    when: "1/1/2023-3/31/2023", // for only specific dates (best for temporary events)
    text: "Carbon Health clinics will be closed for Thanksgiving on Thursday, Nov 25.",
    subtext: <div>Please check specific clinic hours for other holiday <b>changes</b>.<div>,
    pathsInclude: ["/locations/[slug]"],
    type: "info",
    icon: "info",
    "wrapperClasses": "block"
  },
];
*/

const format = "M/D/Y";

// `bannerData` and `now` are added for Storybook. Don't pass them as a prop
export type Props = {
  staticBannerData?: DynamicBannerData[];
  currentState?: string;
};

// staticBannerData and currentState are passed in as a prop for Storybook
const DynamicBanner: React.FC<Props> = ({staticBannerData, currentState}) => {
  const {y} = useScroll();
  const bannerWrapperRef = useRef<HTMLInputElement | null>(null);
  const windowSize = useWindowSize();
  const dispatch = useTypedDispatch();
  const {t} = useTranslation();

  const {pathname} = useRouter();

  const [banners, setBanners] = useState<DynamicBannerData[]>([]);
  const [banner, setBanner] = useState<JSX.Element | null>(null);

  const {locations, locationsSorted} = useTypedSelector(({config}) => config);

  useEffect(() => {
    if (staticBannerData) {
      setBanners(staticBannerData);
    } else {
      fetchFeatureFlags({[FeatureFlag.GROWTH_WEBSITE_BANNER]: []}).then(res => {
        const bannersFetched: DynamicBannerData[] = res[FeatureFlag.GROWTH_WEBSITE_BANNER];
        setBanners(bannersFetched);
      });
    }
  }, [banners, staticBannerData]);

  const onClose = useCallback(id => addToClosedDynamicBannerList(id), []);

  const closedBannersList = useLocalStorageItem(LocalStorageKey.DynamicBannersClosed);

  const bannersNotClosed = useMemo(
    () => banners.filter(banner => !closedBannersList?.includes(banner.id)),
    [banners, closedBannersList],
  );

  useEffect(() => {
    if (!locationsSorted) return;
    // Get the closest state (Didn't use the regions because this works with states)
    const closestState = currentState || locations?.[0]?.address.state;
    const bannersFound = bannersNotClosed.map(banner => {
      const {
        id,
        when,
        pathsInclude,
        pathsExclude,
        type,
        text,
        subtext,
        icon = "info",
        hideIcon = false,
        wrapperClasses,
        states,
        btnText,
        href,
        onCtaClick,
      } = banner;
      const [from, to] = when.split("-");
      const startTime = +moment(from, format);
      const endTime = +moment(to, format).clone().add(1, "days");

      const dateMatch = moment().isBetween(startTime, endTime);
      const pathsMatch = pathsInclude ? pathsInclude.includes(pathname) : true; // if empty, show in all paths
      const pathsExcludeMatch = !pathsExclude?.includes(pathname);
      const statesMatch = states ? states.includes(closestState) : true; // if empty, show in all states.

      if (!dateMatch || !pathsMatch || !pathsExcludeMatch || !statesMatch) return null;

      return (
        <Notice
          key={id}
          type={type}
          text={typeof text === "string" ? t(text) : text}
          subtext={t(subtext || "")}
          icon={icon}
          ignoreRange
          className={`rounded-none min-w-full justify-center ${wrapperClasses || ""}`}
          btnText={t(btnText || "")}
          href={href}
          onCtaClick={onCtaClick}
          onClose={() => onClose(id)}
          hideIcon={hideIcon}
        />
      );
    });
    const bannerFound = compact(bannersFound)[0] || null;
    setBanner(bannerFound);
  }, [
    banners,
    bannersNotClosed,
    closedBannersList,
    currentState,
    dispatch,
    locations,
    locationsSorted,
    onClose,
    pathname,
    t,
  ]);

  useEffect(() => {
    // Get BannersWrapper height and update #banner-spacer's height
    const bannerSpacerEl = document.querySelector<HTMLElement>(`#${bannerSpacer}`);
    const bannersWrapperHeight = bannerWrapperRef.current?.offsetHeight || 0;
    if (bannerSpacerEl) {
      bannerSpacerEl.style.height = `${banner ? bannersWrapperHeight : 0}px`;
    }
  }, [banner, bannerWrapperRef, windowSize]);

  return banner ? (
    <Collapse in={y > -32}>
      <div ref={bannerWrapperRef}>{banner}</div>
    </Collapse>
  ) : null;
};

export default React.memo(DynamicBanner);
